import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, useIntl } from "gatsby-plugin-react-intl"
import PageHeadline from "../components/layout/page-headline"

const NotFoundPage: React.FC = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "page.404.title" })}
        meta={[
          {
            name: "robots",
            content: "noindex, nofollow",
          },
        ]}
      />

      <PageHeadline>
        {intl.formatMessage({ id: "page.404.title" })}
      </PageHeadline>

      <div className="px-container 2xl:container">
        <p>{intl.formatMessage({ id: "page.404.text" })}</p>

        <Link to="/">{intl.formatMessage({ id: "page.404.go_to_home" })}</Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
