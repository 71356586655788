import React from "react"
import Heading from "./heading"

const PageHeadline: React.FC = ({ children }) => {
  return (
    <div className="relative flex flex-col">
      <div className="absolute bottom-1/2 left-0 right-0 top-0 z-[-1] bg-pattern bg-[length:7.6vw_7.6vw] lg:bg-[length:4.25vw_4.25vw]" />
      <div className="my-[16vw] px-[4.16vw] sm:px-[12.32vw] lg:my-[6vw]">
        <Heading importance="h1" className="text-[16vw] sm:text-[9vw]">
          {children}
        </Heading>
      </div>
    </div>
  )
}

export default PageHeadline
